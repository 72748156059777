import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Title from '@components/text/title';
import Content from '@components/text/content';
import Divider from '@components/ui/divider';
import { Button } from '../../../../../../packs/legacy/main.js';
import ModalWrapper from '../modalWrapper';
import styles from './remove.module.scss';

const cx = classNames.bind(styles);

const checkEqual = (prevProps, nextProps) => prevProps === nextProps;

const RemoveModal = ({
  className,
  header,
  footer,
  title,
  content,
  buttons,
  isOpen,
  onClose,
  close,
}) => {
  const classes = cx(
    {
      remove: true,
    },
    className
  );

  return (
    <div className={classes}>
      <ModalWrapper
        header={header}
        footer={footer}
        isOpen={isOpen}
        onClose={onClose}
        close={close}
      >
        <div className={styles.wrapper}>
          {title && <Title align={'center'} size={'h2'} {...title} />}
          {((title && content) || (title && buttons)) && (
            <Divider height={15} color={'transparent'} />
          )}
          {content && <Content align={'center'} color={'dark'} {...content} />}
          {content && buttons && <Divider height={15} color={'transparent'} />}
          {buttons && (
            <div className={styles.buttons}>
              {buttons.map((item, index) => (
                <Button key={index} className={styles.button} {...item} />
              ))}
            </div>
          )}
        </div>
      </ModalWrapper>
    </div>
  );
};

RemoveModal.propTypes = {
  /** Custom classes */
  className: PropTypes.string,
  /** Header of the modal */
  header: PropTypes.shape({
    title: PropTypes.shape(Title.propTypes),
  }),
  /** Footer of the modal */
  footer: PropTypes.shape({
    buttonsLeft: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    buttonsRight: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  }),
  /** Title in the modal */
  title: PropTypes.shape(Title.propTypes),
  /** Content in the modal */
  content: PropTypes.shape(Content.propTypes),
  /** Buttons in the modal */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  /** Should the modal be shown on init */
  isOpen: PropTypes.bool,
  /** The close button of the modal */
  close: PropTypes.bool,
  /** Custom close functionallity */
  onClose: PropTypes.func,
};

RemoveModal.defaultProps = {
  className: '',
  header: null,
  footer: null,
  title: null,
  content: null,
  buttons: null,
  isOpen: false,
  close: true,
  onClose: null,
};

// Needed for Storybook
RemoveModal.displayName = 'RemoveModal';

export default React.memo(RemoveModal, checkEqual);
