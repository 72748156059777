import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tableContainer', 'tableRow', 'blankSlateCard', 'popover'];

  connect() {
    this.observeTableChanges();
    this.checkTableRows();
  }

  observeTableChanges() {
    const config = { childList: true, subtree: true };
    const callback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // Trigger change event on closest form to mark the form as dirty
          const form = this.element.closest('form');
          if (form) {
            form.dispatchEvent(new Event('change'));
          }
          this.checkTableRows();
        }
      }
    };

    this.observer = new MutationObserver(callback);
    this.observer.observe(this.tableContainerTarget, config);
  }

  show() {
    this.tableContainerTarget.style.display = 'block';
  }

  hide() {
    this.tableContainerTarget.style.display = 'none';
  }

  hideCard() {
    this.blankSlateCardTarget.style.display = 'none';
  }

  showCard() {
    this.blankSlateCardTarget.style.display = 'block';
  }

  isRowVisible(row) {
    const style = window.getComputedStyle(row);
    return style.display !== 'none' && style.visibility !== 'hidden';
  }

  checkTableRows() {
    setTimeout(() => {
      let visibleRowCount = 0;
      for (const row of this.tableRowTargets) {
        if (this.isRowVisible(row)) {
          visibleRowCount++;
        }
      }

      if (visibleRowCount === 0) {
        this.hide();
        this.showCard();
      } else {
        this.show();
        this.hideCard();
      }

      // Handle popover disabled state based on visible row count
      this.popoverTargets.forEach((popover) => {
        if (visibleRowCount === 1) {
          popover.disabled = true;
        } else {
          popover.disabled = false;
        }
      });
    }, 10);
  }

  disconnect() {
    // Clean up the observer when the controller is disconnected
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
