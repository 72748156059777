import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@components/ui/tooltip';
import { Spacings, Colors } from '@components/variables';
import classNames from 'classnames/bind';
import Wrapper from '@components/card/wrapper';
import Divider from '@components/ui/divider';
import { Button } from '../../../../../packs/legacy/main.js';
import { ActivationCard } from '../cards';
import styles from './toolbar.module.scss';

const cx = classNames.bind(styles);

const checkEqual = (prevProps, nextProps) => prevProps === nextProps;

const Toolbar = ({
  size,
  color,
  className,
  tooltips,
  buttons,
  activations,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = cx({ toolbar: true, toolbarWide: isOpen }, className);
  return (
    <Wrapper className={classes} size={size} color={color}>
      <div className={styles.wrapper}>
        {activations.length > 0 && (
          <Button
            icon={{ name: 'chevron-right', width: 20, height: 18 }}
            color={'positive'}
            outline={true}
            className={isOpen ? styles.open : styles.close}
            action={() => setIsOpen(!isOpen)}
          />
        )}
        {tooltips && activations.length > 0 && (
          <Divider width={10} color={'transparent'} />
        )}
        {tooltips?.map((item, index) => (
          <div key={index} className={styles.tooltip}>
            <Tooltip simple size={size} color={color} {...item} />
          </div>
        ))}
        {tooltips && buttons && <Divider width={10} color={'transparent'} />}
        {buttons?.map((item, index) => (
          <Button key={index} className={styles.button} {...item} />
        ))}
      </div>
      {isOpen && activations.length > 0 && (
        <div className={styles.content}>
          {activations.map((link, index) => (
            <ActivationCard
              key={index}
              link={link.id}
              size={'xxs'}
              diagram
              color={'balanced-500'}
              title={{
                text: link?.to?.nodeId?.includes('assignment')
                  ? 'Execution condition'
                  : 'Activation condition',
              }}
              activation_rule={{
                rule: link.condition,
              }}
              open={'hidden'}
            />
          ))}
        </div>
      )}
    </Wrapper>
  );
};

Toolbar.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** Tooltips */
  tooltips: PropTypes.arrayOf(PropTypes.shape(Tooltip.propTypes)),
  /** Buttons */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
};

Toolbar.defaultProps = {
  color: 'light',
  size: 'xs',
  className: '',
  tooltips: null,
  buttons: null,
};

// Needed for Storybook
Toolbar.displayName = 'Toolbar';

export default React.memo(Toolbar, checkEqual);
