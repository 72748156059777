import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'addRowButton', 'excludedField'];

  checkFields(showErrors = true) {
    // The delay is needed to support the 'Remove' row. If there is no delay, the fields will still be there when the checkFields function is called.
    setTimeout(() => {
      const fields = this.formTarget.querySelectorAll(
        'rmv-textarea, rmv-input, rmv-select'
      );

      const allFilled = [...fields].every((field) => field.value.trim() !== '');
      this.addRowButtonTargets.forEach((button) => {
        button.disabled = !allFilled;
      });

      if (showErrors) {
        const fieldsArray = Array.from(fields).filter(
          (field) => !this.excludedFieldTargets.includes(field)
        );

        let row = fieldsArray
          .find((field) => field === document.activeElement)
          ?.closest('rmv-row[data-row-id]');

        // Set the field to an error state if it's empty and not the current focused row
        fieldsArray.forEach((field) => {
          const isCurrentRow = row === field.closest('rmv-row[data-row-id]');
          const isEmpty =
            field.value.trim() === '' ||
            (field.tagName.toLowerCase() === 'rmv-select' &&
              field.value === 'false');

          // Set the error based on the conditions
          field.error = isEmpty && !isCurrentRow;
        });
      }
    }, 50);
  }

  disableAddRowButton() {
    this.addRowButtonTargets.forEach((button) => {
      button.disabled = true;
    });

    // Auto focus the first field in the form after clicking the 'Add row' button
    this.focusFirstElement();
  }

  focusFirstElement() {
    const fields = Array.from(
      this.formTarget.querySelectorAll('rmv-textarea, rmv-input')
    );

    // Filter fields to include only those with no value
    const emptyFields = fields.filter((field) => !field.value.trim());

    // Focus on the first empty field, if it exists
    emptyFields[0]?.focus();
  }
}
