import { Controller } from '@hotwired/stimulus';

/**
 * @class TableScrollController
 * @extends Controller
 *
 * This Stimulus controller handles the scroll behavior of a table element.
 * It adds or removes a CSS class based on the scroll position of the table.
 */
export default class extends Controller {
  /**
   * Connects the controller to the DOM element.
   * Sets up event listeners and binds methods.
   */
  connect() {
    /**
     * The event ID for the scroll event.
     * @type {string}
     */
    this.element.eventId = 'scroll-table';

    /**
     * Bound handler for adding the scrolled class.
     * @type {Function}
     */
    this.addScrolledClassHandler = this.addScrolledClass.bind(this);

    /**
     * The table container element.
     * @type {HTMLElement}
     */
    this.tableContainer = this.element.querySelector(
      'rmv-column.table-scrollable'
    );

    this.element.addEventListener(
      'rmv-scrollable:scroll:scroll-table',
      this.addScrolledClassHandler
    );
  }

  /**
   * Adds or removes the 'table-scrollable-scrolled' class based on the scroll position.
   */
  addScrolledClass() {
    if (
      this.element.scrollLeft > 0 &&
      !this.tableContainer.classList.contains('table-scrollable-scrolled')
    ) {
      this.tableContainer.classList.add('table-scrollable-scrolled');
    } else if (
      this.element.scrollLeft <= 0 &&
      this.tableContainer.classList.contains('table-scrollable-scrolled')
    ) {
      this.tableContainer.classList.remove('table-scrollable-scrolled');
    }
  }
}
