import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

/**
 * AsyncSelectController handles asynchronous loading and searching of select options.
 * @extends Controller
 */
export default class extends Controller {
  static values = {
    endpoint: String,
    noresults: String,
    nosearch: String,
  };
  static debounces = ['submit', 'onScroll'];

  /**
   * Connects the controller to the DOM and initializes event listeners.
   */
  connect() {
    useDebounce(this, { wait: 300 });
    this.element.eventId = 'async-select';
    this.searchInput.eventId = 'async-select-search';
    this.offsetValue = 0; // Value to use for the offset parameter in the query for scroll loading
    this.completed = false; // Used for stopping or continuing scroll loading
    this.searching = false; // Used for checking if the user is searching
    this.choicesList = this.element.shadowRoot.querySelector(
      '.choices__list [role="listbox"]'
    );
    this.searchValue = '';
    if (
      this.element.options &&
      this.element.options.length === 1 &&
      this.element.options[0].value === false
    ) {
      this.placeholder = this.nosearchValue;
    }

    this.onScrollHandler = this.onScroll.bind(this);
    this.clearHandler = this.clear.bind(this);
    this.checkSearchHandler = this.checkSearch.bind(this);
    this.saveSearchValueHandler = this.saveSearchValue.bind(this);
    this.preserveSearchValueHandler = this.PreserveSearchValue.bind(this);

    if (this.choicesList) {
      this.choicesList.addEventListener('scroll', this.onScrollHandler);
    }

    if (this.element) {
      this.element.addEventListener(
        'rmv-select:close:async-select',
        this.saveSearchValueHandler
      );

      this.element.addEventListener(
        'rmv-select:open:async-select',
        this.preserveSearchValueHandler
      );
    }

    if (this.searchInput) {
      this.searchInput.addEventListener(
        'rmv-search:input:async-select-search',
        this.checkSearchHandler
      );
    }
  }

  /**
   * Disconnects the controller from the DOM and removes event listeners.
   */
  disconnect() {
    this.abortController?.abort();
    if (this.choicesList) {
      this.choicesList.removeEventListener('scroll', this.onScrollHandler);
    }
    if (this.element) {
      this.element.removeEventListener(
        'rmv-select:open:async-select',
        this.preserveSearchValueHandler
      );
      this.element.removeEventListener(
        'rmv-select:close:async-select',
        this.saveSearchValueHandler
      );
    }
    if (this.searchInput) {
      this.searchInput.removeEventListener(
        'rmv-search:input:async-select-search',
        this.checkSearchHandler
      );
    }
  }

  /**
   * Preserves the current search value in the input field.
   */
  PreserveSearchValue() {
    this.searchInput.value = this.searchValue;
  }

  /**
   * Saves the current search value from the input field.
   */
  saveSearchValue() {
    this.searchValue = this.searchInput.value;
  }

  /**
   * Checks the search input and clears the list if the input is empty.
   */
  checkSearch() {
    if (!this.searchInput.value) {
      this.clear();
    } else {
      this.choicesList.scrollTop = 0;
      this.searching = true;
    }
  }

  /**
   * Handles the scroll event to load more items when the user scrolls to the bottom.
   */
  onScroll() {
    const { scrollTop, scrollHeight, clientHeight } = this.choicesList;
    const triggerOffset = 5;
    if (scrollTop + clientHeight >= scrollHeight - triggerOffset) {
      this.searching = false;
      this.loadMoreItems();
    }
  }

  /**
   * Gets the search input element.
   * @returns {HTMLElement} The search input element.
   */
  get searchInput() {
    return this.element.shadowRoot.querySelector('[name="search_terms"]');
  }

  /**
   * Gets the placeholder element.
   * @returns {HTMLElement} The placeholder element.
   */
  get placeholder() {
    return this.element.shadowRoot.querySelector(
      '.choices__item.has-no-results'
    );
  }

  /**
   * Sets the placeholder element.
   * @param {string} value - The placeholder text.
   */
  set placeholder(value) {
    if (value && !this.placeholder) {
      const cardPlaceholder = document.createElement('rmv-button');
      cardPlaceholder.variant = 'tertiary';
      cardPlaceholder.setAttribute('text', value);
      cardPlaceholder.classList.add('choices__item');
      cardPlaceholder.classList.add('has-no-results');
      cardPlaceholder.id = 'placeholder';
      const listBox = this.element.shadowRoot.querySelector(
        '.choices__list [role="listbox"]'
      );
      listBox.append(cardPlaceholder);
    } else if (!value) {
      this.element.shadowRoot.querySelector('#placeholder').remove();
    } else {
      this.placeholder.text = value;
    }
  }

  /**
   * Gets the spinner element.
   * @returns {HTMLElement} The spinner element.
   */
  get spinner() {
    return this.element.querySelector('rmv-spinner');
  }

  /**
   * Sets the spinner element.
   * @param {boolean} value - Whether to show the spinner.
   */
  set spinner(value) {
    if (!this.spinner) {
      const spinner = document.createElement('rmv-spinner');
      spinner.hidden = !value;
      spinner.slot = 'prefix';
      this.element.append(spinner);
    } else {
      this.spinner.hidden = !value;
    }
  }

  /**
   * Constructs the endpoint URL for fetching data.
   * @param {boolean} [isLoadMore=false] - Whether to load more items.
   * @param {boolean} [adjustedOffset=false] - Whether the offset has been adjusted.
   * @returns {string} The constructed endpoint URL.
   */
  getEndpoint(isLoadMore = false, adjustedOffset = false) {
    const url = this.endpointValue;
    const rootUrl = url.startsWith('http')
      ? url
      : [window.location.origin, url.startsWith('/') ? url.slice(1) : url].join(
          '/'
        );
    const urlObject = new URL(rootUrl);
    const paramsObject = new URLSearchParams({
      query: this.searchInput.value,
    });

    if (isLoadMore) {
      if (!adjustedOffset) {
        this.offsetValue = this.element.options.length - 1;
      }
      paramsObject.append('offset', this.offsetValue);
      paramsObject.append('limit', 10);
    }

    for (const [key, value] of paramsObject) {
      urlObject.searchParams.append(key, value);
    }
    return urlObject.toString();
  }

  /**
   * Clears the search results and resets the state.
   */
  clear() {
    this.choicesList.scrollTop = 0;
    this.placeholder = this.nosearchValue;
    this.completed = true;
  }

  /**
   * Loads more items by submitting a request.
   */
  loadMoreItems() {
    this.submit({ isLoadMore: true });
  }

  /**
   * Updates the options of the select element with the merged options.
   * It also maintains the current scroll position of the choices list.
   *
   * @param {Array} mergedOptions - The array of merged options to update.
   */
  updateOptions(mergedOptions) {
    const optionsToUpdate = this.element.options;
    const currentScrollTop = this.choicesList.scrollTop;

    const existingOptionValues = new Set(
      optionsToUpdate.map((option) => option.value)
    );

    const filteredOptions = mergedOptions.filter(
      (option) => !existingOptionValues.has(option.value)
    );

    // Get selected values from the 'values' attribute
    const selectedValues = new Set(
      this.element.getAttribute('value').split(',')
    );

    // Update options and set selected to true for matching values
    const updatedOptions = Array.from(optionsToUpdate).map((option) => {
      const mergedOption = mergedOptions.find(
        (opt) => opt.value === option.value
      );
      if (mergedOption) {
        return mergedOption;
      }
      option.selected = selectedValues.has(option.value);
      return option;
    });

    updatedOptions.push(...filteredOptions);
    this.element.options = JSON.stringify(updatedOptions);

    /* 
    ** requestAnimationFrame() tells the browser you wish to perform an animation frame request 
      and call a user-supplied callback function before the next repaint.
      While there is no animation, this helps to restore the scroll position after the options are updated.
    */
    requestAnimationFrame(() => {
      this.choicesList.scrollTop = currentScrollTop;
    });
  }

  /**
   * Submits a request to fetch data.
   * @param {Object} [options={}] - The options for the request.
   * @param {boolean} [options.isLoadMore=false] - Whether to load more items.
   */
  async submit({ isLoadMore = false } = {}) {
    if (
      (this.completed ||
        this.element.options.length > this.element.searchResultLimit) &&
      isLoadMore
    ) {
      return;
    }
    // Abort the previous fetch request if any
    this.abortController?.abort();
    // Create a new AbortController for the new fetch request
    this.abortController = new AbortController();

    // Always show the spinner when making a request
    this.spinner = true;
    try {
      let data;
      const response = await fetch(this.getEndpoint(isLoadMore), {
        signal: this.abortController.signal,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      data = await response.json();

      // Get current values in the select
      const currentOptionValues = new Set(
        Array.from(this.element.options)
          .filter((option) => option.selected && !option.placeholder)
          .map((option) => option.value)
      );

      if (this.element.value && currentOptionValues.size === 0) {
        console.warn(
          'There is a value on the select but no option with an selected attribute set to true. If this select supports lazy loading, it may not function correctly when items are selected.'
        );
      }

      if (isLoadMore) {
        let adjustedOffset = false; // whether the offset needs to be adjusted to accomodate for selected items
        let previousDataOptions = this.previousDataOptions || [];

        // Extract values from previous data fetch
        const previousDataOptionValues = new Set(
          previousDataOptions.map((option) => option.value)
        );

        // Check for values in this.element.options that are not in the previous data fetch
        const missingValues = Array.from(currentOptionValues).filter(
          (value) => !previousDataOptionValues.has(value)
        );

        // check if there is any difference between the previous data fetch and the current options in the select
        if (missingValues.length > 0) {
          this.offsetValue =
            this.element.options.length - 1 - missingValues.length;
          adjustedOffset = true;

          // Refetch the data with the adjusted offset
          const adjustedResponse = await fetch(
            this.getEndpoint(isLoadMore, adjustedOffset),
            {
              signal: this.abortController.signal,
            }
          );
          if (!adjustedResponse.ok) {
            throw new Error(`HTTP error! status: ${adjustedResponse.status}`);
          }
          data = await adjustedResponse.json(); // Reassign data to adjusted response
        }

        // Store the current data.options for the next request
        this.previousDataOptions = data.options;
      }

      // check if limit and offset exist in the json coming from the ruby api controller, otherwise stop making requests
      const { limit, offset } = data;

      if ((!limit || !offset) && isLoadMore) {
        this.completed = true;
        return;
      }

      // Get current selected options
      const selectedValues = new Set(this.element.value.split(','));

      const selectedOptions = this.element.options
        .filter((option) => selectedValues.has(String(option.value)))
        .map((option) => ({ ...option, selected: true }));

      // Check if all options already exist in the select element
      const existingOptions = Array.from(this.element.options).map(
        (option) => option.value
      );
      const allOptionsExist = data.options.every((option) =>
        existingOptions.includes(option.value)
      );

      // if there are no new options, cancel the function
      if (allOptionsExist && isLoadMore) {
        this.completed = true;
        return;
      }

      if (data.options && data.options.length > 0) {
        const options = data.options.map((option) => {
          return {
            ...option,
            label: option.label || option.title,
          };
        });

        // Create a map for easy lookup of existing answers
        const existingAnswersMap = new Map(
          options.map((answer) => [answer.value, answer])
        );

        // Merge new data with selected options
        const mergedOptions = [
          ...options.map((answer) => ({
            ...answer,
            selected: selectedValues.has(String(answer.value)),
          })),
          ...selectedOptions
            .filter((option) => !existingAnswersMap.has(option.value))
            .map((option) => ({ ...option, selected: true })),
        ];

        this.completed = false;

        // If it's a load more request, append the new options to the existing options
        if (isLoadMore && !this.searching) {
          this.updateOptions(mergedOptions);
        } else {
          this.element.options = JSON.stringify(mergedOptions);
        }
      } else {
        if (isLoadMore) {
          this.completed = true;
        } else {
          // Keep the current selected options
          this.element.options = JSON.stringify(selectedOptions);
          this.placeholder = this.noresultsValue;
        }
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Fetch Error:', error);
      }
    } finally {
      this.spinner = false;
    }
  }
}
